<template>
  <div class="benefit-withdraw-log">
    <div class="content-inner">
      <div class="benefit-header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/web/user/benefit' }"
            >我的收益</el-breadcrumb-item
          >
          <el-breadcrumb-item>提现记录</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="teams-list">
        <div class="list-header">
          <div style="width: 20%">订单编号</div>
          <div style="width: 20%">申请时间</div>
          <div style="width: 20%">提现金额</div>
          <div style="width: 20%">提现支付宝账号</div>
          <div style="width: 20%">状态</div>
        </div>
        <div v-loading="loading" class="list-content">
          <div v-for="item in resultList" :key="item.id" class="list-item">
            <div style="width: 25%">{{ item.id }}</div>
            <div style="width: 25%">{{ item.cdatetime }}</div>
            <div style="width: 25%">{{ item.money_withdraw }}</div>
            <div style="width: 25%">{{ item.vip_level }}</div>
            <div style="width: 25%">{{ status[item.withdraw_status] }}</div>
          </div>
        </div>
        <el-pagination
          class="pager"
          background
          layout="prev, pager, next"
          :page-count="maxPage"
          @prev-click="fetchList"
          @next-click="fetchList"
          @current-change="fetchList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Breadcrumb, BreadcrumbItem, Pagination } from "element-ui";
import apis from "@/apis/index";

const TYPES = {
  first: "first",
  second: "second"
};

// const mockData = new Array(30).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   real_name: "名字1",
//   mobile: "18573309100",
//   vip_level: [0, 1][index % 2]
// }));

export default {
  name: "BenefitWithdrawLog",
  components: {
    "el-pagination": Pagination,
    "el-breadcrumb": Breadcrumb,
    "el-breadcrumb-item": BreadcrumbItem
  },
  data() {
    return {
      TYPES,
      page: 1,
      maxPage: 0,
      loading: false,
      resultList: [],
      status: {
        0: "审核中",
        1: "提现成功",
        2: "提现失败"
      }
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList(page) {
      this.page = page;
      this.loading = true;
      try {
        const {
          data: { user_withdraw_list, max_page }
        } = await apis.userWithdrawIndex({ page: this.page });
        this.maxPage = max_page;
        this.resultList = user_withdraw_list;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.benefit-withdraw-log {
  // margin: 20px;
  padding: 20px;
  box-sizing: border-box;
  height: calc(100% - 90px);

  .content-inner {
    height: 100%;
    width: 100%;
    background: #f7f7f7;
    border-radius: 4px;
    // padding: 0 20px;
    box-sizing: border-box;
  }

  .benefit-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .title-text {
      font-size: 14px;
      color: #666666;
    }
  }

  .dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;

    .dashboard-item {
      width: 330px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(139, 66, 51, 0.5);
      border: 1px solid #CA0A15;
      border-radius: 4px;
      color: #ffffff;
      position: relative;

      .money {
        font-size: 36px;
        font-weight: bold;

        &::before {
          content: "¥";
          font-size: 14px;
        }
      }

      .label {
        font-size: 14px;
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .teams-list {
    height: calc(100% - 46px);
    padding-top: 20px;
    margin: 0 20px;

    .list-header {
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      color: #333333;
      font-size: 14px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 20px;
    }

    .list-content {
      padding: 0 20px;
      display: flex;
      flex-flow: column nowrap;
      overflow: auto;
      height: calc(100% - 60px);
      // flex: none;

      .list-item {
        flex: none;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 50px;
        font-size: 12px;
        color: #666666;
        border-bottom: 1px solid #f0f0f0;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}

.pager {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
</style>
